"use client";
import { useMemo, useState } from "react";
import cn from "@/libs/cn";
import CustomScroll from "@/components/CustomScroll";
import useClientContext from "@/context/ClientContext";
import Checkbox from "@/controls/Checkbox";
import { FilterListData } from "../FilterCheckbox";

import styles from "./styles.module.scss";

interface FilterList {
  className?: string;
  label: string;
  quantity?: any;
  data: FilterListData[];
  checked: any[];
  minForScroll?: number;
  itemHeight?: number;
  gap?: number;
  quantityCollapsedList?: number;
  quantityScrollList?: number;
  onChange: (value: any, checked: boolean) => void;
  renderItemLabel?: (item: any) => JSX.Element;
}

const FilterList = (props: FilterList) => {
  const {
    className,
    label,
    quantity,
    data,
    checked,
    minForScroll = 3,
    gap = 16,
    itemHeight = 20,
    quantityCollapsedList = 4,
    quantityScrollList = 3,
    onChange,
    renderItemLabel,
  } = props;
  const { translate } = useClientContext();
  const [show, setShow] = useState(false);

  const hideScroll = data.length <= minForScroll;

  const minHeight = quantityScrollList * itemHeight + (quantityScrollList - 1) * gap;
  const maxHeight = quantityCollapsedList * itemHeight + (quantityCollapsedList - 1) * gap;

  const list = useMemo(
    () =>
      data.map((item) => (
        <div key={item.value} className={styles.item}>
          <Checkbox
            label={
              renderItemLabel ? (
                renderItemLabel(item)
              ) : (
                <div className={styles.customLabel} title={item.label}>
                  {item.label}
                </div>
              )
            }
            checked={checked?.map((i) => `${i}`)?.includes(`${item.value}`)}
            onChange={(e) => onChange(item.value, e.target.checked)}
          />
          <div className={styles.quantity}>{item.quantity}</div>
        </div>
      )),
    [checked, data, onChange, renderItemLabel]
  );

  return (
    <div className={cn(styles.FilterList, className)}>
      <div className={styles.header}>
        <div className={styles.label}>{label}</div>
        {/*<div className={styles.quantity}>{quantity}</div>*/}
        <div className={styles.quantity}>{list.length}</div>
      </div>
      {hideScroll || show ? (
        <div className={styles.list}>{list}</div>
      ) : (
        <CustomScroll classNameContent={styles.list} minHeight={minHeight} maxHeight={maxHeight}>
          {list}
        </CustomScroll>
      )}
      {!hideScroll && list.length > quantityCollapsedList && (
        <div onClick={() => setShow(!show)} className={styles.show}>
          {show ? translate("Show less") : translate("Show all")}
        </div>
      )}
    </div>
  );
};

export default FilterList;
